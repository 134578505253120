import { useState, useEffect, useCallback, useRef } from 'react'
import { Button } from "../../components/ui/button"
import { ScrollArea } from "../../components/ui/scroll-area"
import { Input } from "../../components/ui/input"
import { Textarea } from "../../components/ui/textarea"
import { Label } from "../../components/ui/label"
import { TagPanel } from "../../components/tag-panel";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { PlusCircle, Send, MoreHorizontal, Trash, Edit2, Loader2, Tag } from "lucide-react"
import Navbar from "../../components/navbar"
import ReactMarkdown from 'react-markdown'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
} from "../../components/ui/dialog"

import {
    ChatMessage,
    ChatSession,
    getChatSessions,
    getChatMessages,
    addChatSession,
    addChatMessage,
    deleteChatSession,
    updateChatSession
} from '../../apis/chat'

export default function Component() {
    const [chatSessions, setChatSessions] = useState<ChatSession[]>([])
    const [currentSessionId, setCurrentSessionId] = useState<string | null>(null)
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [inputMessage, setInputMessage] = useState('')
    const [editingSessionId, setEditingSessionId] = useState<string | null>(null)
    const [editingTitle, setEditingTitle] = useState('')
    const [isInitialized, setIsInitialized] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const scrollAreaRef = useRef<HTMLDivElement>(null)
    const messagesEndRef = useRef<HTMLDivElement>(null)
    const [isTagModalOpen, setIsTagModalOpen] = useState(false)
    const [tagInput, setTagInput] = useState('')
    const [tagSessionId, setTagSessionId] = useState<string | null>(null)


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [messages])

    const fetchChatSessions = useCallback(async () => {
        try {
            const sessions = await getChatSessions()
            setChatSessions(sessions)
            return sessions
        } catch (error) {
            console.error('Failed to fetch chat sessions:', error)
            return []
        }
    }, [])

    const startNewChat = useCallback(async () => {
        try {
            const newSession = await addChatSession()
            setChatSessions(prev => [newSession, ...prev])
            setCurrentSessionId(newSession.sessionId)
            setMessages([])
            return newSession
        } catch (error) {
            console.error('Failed to create new chat session:', error)
            return null
        }
    }, [])

    useEffect(() => {
        const initializeChat = async () => {
            if (isInitialized) return

            const sessions = await fetchChatSessions()
            if (sessions.length === 0) {
                await startNewChat()
            } else {
                setCurrentSessionId(sessions[0].sessionId)
            }
            setIsInitialized(true)
        }
        initializeChat()
    }, [fetchChatSessions, startNewChat, isInitialized])

    useEffect(() => {
        if (currentSessionId) {
            fetchChatMessages(currentSessionId)
        }
    }, [currentSessionId])

    const fetchChatMessages = async (sessionId: string) => {
        try {
            const fetchedMessages = await getChatMessages(sessionId)
            setMessages(fetchedMessages)
        } catch (error) {
            console.error('Failed to fetch chat messages:', error)
        }
    }

    const selectChat = (sessionId: string) => {
        setCurrentSessionId(sessionId)
    }

    const deleteChat = async (sessionId: string) => {
        try {
            await deleteChatSession(sessionId)
            setChatSessions(prev => prev.filter(session => session.sessionId !== sessionId))
            if (currentSessionId === sessionId) {
                const remainingSessions = chatSessions.filter(session => session.sessionId !== sessionId)
                if (remainingSessions.length > 0) {
                    setCurrentSessionId(remainingSessions[0].sessionId)
                } else {
                    setCurrentSessionId(null)
                    setMessages([])
                }
            }
        } catch (error) {
            console.error('Failed to delete chat session:', error)
        }
    }

    const startEditingChat = (sessionId: string, currentTitle: string) => {
        setEditingSessionId(sessionId)
        setEditingTitle(currentTitle)
    }

    const saveEditedChat = async () => {
        if (editingSessionId) {
            try {
                const updatedSession = { sessionId: editingSessionId, title: editingTitle }
                await updateChatSession(updatedSession)
                setChatSessions(prev => prev.map(session =>
                    session.sessionId === editingSessionId ? updatedSession : session
                ))
                setEditingSessionId(null)
                setEditingTitle('')
            } catch (error) {
                console.error('Failed to update chat session:', error)
            }
        }
    }

    const addTag = async (sessionId: string) => {
        const session = chatSessions.find(s => s.sessionId === sessionId)
        if (session) {
            const { tags = [] } = session;
            setIsTagModalOpen(true)
            setTagSessionId(sessionId)
            setTagInput(tags.join(', '))
        }
    }

    const sendMessage = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!inputMessage.trim() || !currentSessionId) return

        try {
            const message: ChatMessage = { id: null, sessionId: currentSessionId, role: 'user', content: inputMessage };
            setMessages(prev => [...prev, message])
            setInputMessage('')
            setIsProcessing(true)


            const response = await addChatMessage(message);
            setMessages(prev => [...prev, response])


        } catch (error) {
            console.error('Failed to send message:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    return (
        <div className="flex flex-col h-screen">
            <Navbar />
            <div className="flex flex-1 overflow-hidden">
                <div className="w-64 border-r border-gray-200 dark:border-gray-800 bg-background overflow-y-auto">
                    <div className="p-4">
                        <Button onClick={startNewChat} className="w-full justify-start" variant="outline">
                            <PlusCircle className="mr-2 h-4 w-4" />
                            New Chat
                        </Button>
                    </div>
                    <div className="px-4 py-2 text-sm font-medium text-muted-foreground">Recent Chats</div>
                    <ScrollArea className="h-[calc(100vh-10rem)]">
                        {chatSessions.map(session => (
                            <div key={session.sessionId} className="group px-2 py-1">
                                <div className="flex items-center rounded-md border border-gray-200 dark:border-gray-700 bg-background transition-colors duration-200">
                                    {editingSessionId === session.sessionId ? (
                                        <form onSubmit={(e) => { e.preventDefault(); saveEditedChat(); }} className="flex-1 px-2">
                                            <Input
                                                value={editingTitle}
                                                onChange={(e) => setEditingTitle(e.target.value)}
                                                onBlur={saveEditedChat}
                                                autoFocus
                                                className="h-8 text-sm"
                                            />
                                        </form>
                                    ) : (
                                        <Button
                                            onClick={() => selectChat(session.sessionId)}
                                            className={`w-full justify-start font-normal h-8 px-2 ${session.sessionId === currentSessionId ? 'bg-accent' : ''}`}
                                            variant="ghost"
                                        >
                                            <span className="text-sm truncate">{session.title}</span>
                                        </Button>
                                    )}
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                size="icon"
                                                variant="ghost"
                                                className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                            >
                                                <MoreHorizontal className="h-4 w-4" />
                                                <span className="sr-only">More options</span>
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                            <DropdownMenuItem onClick={() => startEditingChat(session.sessionId, session.title)}>
                                                <Edit2 className="mr-2 h-4 w-4" />
                                                Rename
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => addTag(session.sessionId)}>
                                                <Tag className="mr-2 h-4 w-4" />
                                                Update Tags
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => deleteChat(session.sessionId)}>
                                                <Trash className="mr-2 h-4 w-4" />
                                                Delete
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                                {session.tags && session.tags.length > 0 && (
                                    <div className="mt-1 flex flex-wrap gap-1">
                                        {session.tags.map((tag, index) => (
                                            <span key={index} className="text-xs bg-accent text-accent-foreground px-2 py-1 rounded-full">
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </ScrollArea>
                </div>
                <div className="flex-1 flex flex-col">
                    <TagPanel />
                    <ScrollArea className="flex-1 p-4" ref={scrollAreaRef}>
                        {messages.map((message) => (
                            <div key={message.id} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                                <div className={`inline-block p-3 rounded-lg ${message.role === 'user'
                                    ? "bg-primary text-primary-foreground rounded-br-none"
                                    : "bg-secondary text-secondary-foreground rounded-bl-none"
                                    }`}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                        {isProcessing && (<Loader2 className="h-4 w-4 ml-2 inline animate-spin" />)}
                    </ScrollArea>
                    <form onSubmit={sendMessage} className="p-4 border-t border-gray-200 dark:border-gray-800">
                        <div className="flex items-center space-x-2">
                            <Textarea
                                value={inputMessage}
                                onChange={(e: any) => setInputMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-1 min-h-[100px]"
                                disabled={isProcessing}
                            />
                            <Button type="submit" size="icon" disabled={isProcessing || !inputMessage.trim()}>
                                <Send className="h-4 w-4" />
                                <span className="sr-only">Send</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <Dialog open={isTagModalOpen} onOpenChange={setIsTagModalOpen}>
                <DialogContent className="max-w-sm">
                    <DialogHeader>
                        <DialogTitle>Update tags</DialogTitle>
                    </DialogHeader>
                    <div className="py-4">
                        <div className="flex items-center gap-4">
                            <Label htmlFor="tag" className="whitespace-nowrap">
                                Tags
                            </Label>
                            <Input
                                id="tag"
                                value={tagInput}
                                onChange={(e) => setTagInput(e.target.value)}
                                className="flex-grow"
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button type="submit" onClick={() => { }}>Update</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}