'use client'

import React, { useEffect, useState, useRef } from "react";
import { Loader2 } from 'lucide-react'
import { DocumentWithComments } from '../apis/documents';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"


type AnalysisResult = {
  'original content': string
  compliance_checks: string[]
  feedbacks: string[]
}

type DocumentPage = {
  page: number
  content: string
  analysis_results: AnalysisResult[]
  source: string[]
}

interface HighlightedDocumentPageProps {
  documentPage: DocumentPage;
}

const HighlightedDocumentPage: React.FC<HighlightedDocumentPageProps> = ({ documentPage }) => {
  const feedbackBoxRef = useRef<HTMLDivElement | null>(null);
  const highlightRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const [activeTab, setActiveTab] = useState("feedback")


  useEffect(() => {
    if (highlightRefs.current.length > 0 && feedbackBoxRef.current) {
      const firstHighlight = highlightRefs.current.find((el) => el !== null);
      const feedbackBox = feedbackBoxRef.current;

      if (firstHighlight) {
        const highlightRect = firstHighlight.getBoundingClientRect();
        const containerRect = feedbackBox.parentElement?.getBoundingClientRect();

        if (containerRect) {
          feedbackBox.style.position = "absolute";
          feedbackBox.style.top = `${highlightRect.top - containerRect.top}px`;
        }
      }
    }
  }, [documentPage]);

  const getHighlightedContent = (content: string, analysisResults: AnalysisResult[]) => {
    let currentIndex = 0;
    const elements: React.ReactNode[] = [];

    analysisResults.forEach((result, index) => {
      const normalizedContent = content.replace(/\s+/g, " ");
      const normalizedOriginal = result["original content"].replace(/\s+/g, " ");
      const startIndex = normalizedContent.indexOf(normalizedOriginal, currentIndex);

      if (startIndex !== -1) {
        // Add non-highlighted text before the match
        elements.push(content.slice(currentIndex, startIndex));

        // Add highlighted text
        const endIndex = startIndex + result["original content"].length;
        elements.push(
          <span
            key={index}
            ref={(el) => (highlightRefs.current[index] = el)}
            className="bg-yellow-300 px-1 rounded-sm"
          >
            {content.slice(startIndex, endIndex)}
          </span>
        );

        // Update current index
        currentIndex = endIndex;
      }
    });

    // Add remaining non-highlighted text
    elements.push(content.slice(currentIndex));
    return elements;
  };

  return (
    <div className="relative flex items-start space-x-5">
      {/* Main content */}
      <div className="bg-gray-300 p-4  w-1/2">
        {getHighlightedContent(documentPage.content, documentPage.analysis_results)}
      </div>

      {/* Feedback box */}
      {documentPage.analysis_results[0] &&
        <div
          ref={feedbackBoxRef}
          className="absolute right-0 bg-white border-2 border-gray-300 rounded-lg px-4 py-2 shadow-md w-1/2"
        >
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="feedback">Feedback</TabsTrigger>
              <TabsTrigger value="compliance">Compliance Checks</TabsTrigger>
            </TabsList>
            <TabsContent value="feedback">
              <ul className="list-disc pl-5 mt-2 space-y-2">
                {documentPage.analysis_results.flatMap((result) =>
                  result.feedbacks.map((feedback, idx) => (
                    <li key={`${result["original content"]}-feedback-${idx}`} className="text-gray-700">
                      {feedback}
                    </li>
                  ))
                )}
              </ul>
            </TabsContent>
            <TabsContent value="compliance">
              <ul className="list-disc pl-5 mt-2 space-y-2">
                {documentPage.analysis_results.flatMap((result) =>
                  result.compliance_checks.map((check, idx) => (
                    <li key={`${result["original content"]}-compliance-${idx}`} className="text-gray-700">
                      {check}
                    </li>
                  ))
                )}
              </ul>
            </TabsContent>
          </Tabs>
        </div>
      }
    </div>
  );
};

interface DocumentViewerProps {
  document: DocumentWithComments;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = ({ document }) => {
  const [documentData, setDocumentData] = useState<DocumentPage[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (document.responseS3Url) {
        try {
          const response = await fetch(document.responseS3Url)
          if (!response.ok) {
            throw new Error('Failed to fetch document data')
          }
          const { data } = await response.json()

          setDocumentData(data[0].result)
        } catch (err) {
          setError(err instanceof Error ? err.message : 'An unknown error occurred')
        } finally {
          setIsLoading(false)
        }
      }

    }

    fetchData()
  }, [document])

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    )
  }

  if (documentData.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>No document data available.</p>
      </div>
    )
  }

  return (
    <div className="container mx-auto py-6">
      <div className="pr-6">
        <h2 className="text-2xl font-bold mb-4">{document.name}</h2>
        <h3 className="text mb-4">{document.description}</h3>
        {documentData.map((page) => (
          <HighlightedDocumentPage documentPage={page} />
        ))}
      </div>
    </div>
  )
}